.btn {
  min-height: 40px;
  text-transform: none;
  font-size: 15px;
  box-shadow: none !important;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 5px;

  &.btn-sm {
    padding: 4px 8px;
    margin-bottom: 0;
    line-height: 20px;
    font-weight: 800;
    min-height: 0;
    font-size: 12px;
  }

  &.btn-xsm {
    min-height: 0 !important;
    height: 22px !important;
    padding: 0 6px !important;
    line-height: 20px !important;
    text-transform: none !important;
    font-weight: 600;
  }

  &.active .fa.check-icon {
    line-height: 17px;
    color: theme-color("secondary");
    padding: 7px 10px;
    border-radius: 100%;
    background: white;
    font-size: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  }

  &:focus,
  .focus {
    outline: 0;
    box-shadow: none !important;
  }
}

.btn-hoverable .hidden-hover {
  display: none;
}
.btn-hoverable:hover .hidden-hover {
  display: inline-block;
}

.btn-group button {
  min-height: 50px;
  margin-right: 0;
  margin-bottom: 10px;
  // background: white;
  padding: 8px 15px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-transform: none;
  flex: 1;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: theme-color-level(default, -2);
}
.btn.btn-success {
  color: white;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
