$theme-colors: (
  "primary": #1f0065,
  "secondary": #ff4160,
  "tertiary": #00d7e5,
  "success": #00d7e5,
  "info": #818686,
  "warning": #ffcc99,
  "dark": #222222,
  "danger": #dc5f45,
  "light": #e2e8ed,
  "default": #f1f2f3,
  "trans": transparent,
);

$border-radius: 0px;
$ligthtingIntensity: 10%;
$darkeningIntensity: 10%;
$body: #e2e8ed;
