// Print rules

@media print {
  .bm-menu-wrap,
  .no-print {
    display: none;
  }
  #main-content {
    width: 100% !important;
    transform: translate3d(0, 0, 0) !important;
  }
  .page-break {
    display: inline-block !important;
    page-break-inside: avoid;
  }
  .print-container {
    display: block !important;
    width: 100vw;
  }

  @page {
    margin-bottom: 0;
  }
}

.print-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}