@import "./colors.scss";
@import "node_modules/bootstrap/scss/bootstrap";
@import "./buttons.scss";
@import "./inputs.scss";
@import "./titles.scss";
@import "./medias.scss";

html,
body,
#root {
  min-height: 100%;
  background: #ececec;
}
body {
  margin: 0;
  font-family: Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 14px;
}
:focus {
  outline: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.progress_text {
  font-size: 8px;
  color: #4c4c4c;
  position: absolute;
  opacity: 1;
  transition: opacity 1000ms ease-out;
  mix-blend-mode: difference;
  width: 100%;
  text-align: right;
  height: 10px;
  line-height: 10px;
  border-radius: 10px;
  top: 6px;
}
.overlay_loading {
  position: absolute;
  z-index: 999;
  height: 104%;
  width: 104%;
  left: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffb8;
}

.btn-group {
  display: flex;
  flex-direction: column;
}

.card-header {
  background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
  color: white;
}
.breadcrumb-custom {
  background: #299af3;

  .col {
    height: 25px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #aad2f3;
      border-right: 13px solid #c0e3ff;
      transform-origin: 100% 100%;
      transform: skewX(30deg);
      transition: 1s;
    }

    span {
      color: white;
      position: absolute;
      line-height: 25px;
      left: 3%;
    }

    .active {
      &:before {
        background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
        border-right: 13px solid rgb(107, 190, 255);
      }
    }
  }
}

.load_ls_overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff9c;
  z-index: 99;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
}
.Toastify__toast-container {
  z-index: 99999999 !important;
}
.Toastify__toast {
  font-family: Montserrat, sans-serif !important;
  border-radius: 5px !important;
}
.Toastify__toast--error {
  background: #dc7c7c;
}
.Toastify__toast--success {
  background: #36767e;
}
.card {
  overflow: hidden;
  .card-header {
    text-align: center;
  }
}
.navbar-brand {
  font-size: 18px;
}
.login-sidenav {
  background-image: url(/images/login-bg.jpg);
  background-size: cover;
  background-position: center;
}

.form-text {
  text-align: left;
}
.rounded {
  border-radius: 5px !important;
}
.modifyInputBtn {
  opacity: 0.4;
  top: 27px;
  right: 0px;
  height: 39px;
  padding: 0 12px;
}
.emailContainer:hover .modifyInputBtn {
  opacity: 1;
}
.resendMail {
  .overinfo {
    display: none;
    text-transform: none !important;
  }

  &:hover,
  &.isactive {
    .overinfo {
      display: inline-block;
    }
  }
}

.highlight {
  background: yellow;
}

.acc-btn:hover {
  background: rgba(128, 128, 128, 0.01) !important;
}
.grecaptcha-badge {
  opacity: 0;
}
.avatar {
  transition: 400ms;
}
.avatar:hover {
  transform: scale(1.05);
}
.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-grey {
  color: grey;
  &:hover {
    color: black;
  }
}
.modal-content {
  //box-shadow: 0 6px 216px 216px rgba(0, 0, 0, 0.68);
}

.example-enter {
  margin-top: -200px;
  opacity: 0;
}

.example-enter.example-enter-active {
  margin-top: 0;
  opacity: 1;
  transition: 200ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0;
  transition: 10ms ease-in;
}

.nav-tabs .nav-link {
  background: theme-color("default");
}
.form-editor .empty-button {
  display: none !important;
}
.nav-link.q-selected {
  background-color: theme-color("primary") !important;
  border-color: theme-color("primary") !important;
  color: white !important;
  font-weight: bold !important;
}
.form-image {
  max-height: 200px;
  max-width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.badge {
  border-radius: 5px;
}

:export {
  default: theme-color("default");
  defaultLight: theme-color-level(default, -2);
  defaultDark: theme-color-level(default, 2);
  primary: theme-color("primary");
  primaryLight: theme-color-level(primary, -2);
  primaryDark: theme-color-level(primary, 2);
  secondary: theme-color("secondary");
  secondaryLight: theme-color-level(secondary, -2);
  secondaryDark: theme-color-level(secondary, 2);
  tertiary: theme-color("tertiary");
  tertiaryLight: theme-color-level(tertiary, -2);
  tertiaryDark: theme-color-level(tertiary, 2);
  success: theme-color("success");
  successLight: theme-color-level(success, -2);
  successDark: theme-color-level(success, 2);
  info: theme-color("info");
  infoLight: theme-color-level(info, -2);
  infoDark: theme-color-level(info, 2);
  danger: theme-color("danger");
  dangerLight: theme-color-level(danger, -2);
  dangerDark: theme-color-level(danger, 2);
  warning: theme-color("warning");
  warningLight: theme-color-level(warning, -2);
  warningDark: theme-color-level(warning, 2);
  dark: theme-color("dark");
  darkLight: theme-color-level(dark, -2);
  darkDark: theme-color-level(dark, 2);
  light: theme-color("light");
  lightLight: theme-color-level(light, -2);
  lightDark: theme-color-level(light, 2);
  grey: theme-color("grey");
}
