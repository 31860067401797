.container {
    width: 100%;
}

.sigContainer {
    margin: 0 auto;
}

.sigPad {
    border: 2px rgba(0, 0, 0, 0.5) dashed;
    border-radius: 5px;
    background-color: #fff;
}

.buttons {
    width: 100%;
    height: 30px;
}

.sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
}
