#mission_container {
  position: relative;
    text-align: center;
    font-family: Raleway;
    height: 641px;
    border-radius: 30px;
    padding: 57px 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 28px;
    background: transparent;
}
#preview_container {
      position: relative;
    width: 330px;
    height: 675px;
    margin: 0px auto;
    background-image: url(/mobile_bg.png);
    background-repeat: no-repeat;
}
#mission_section_title {
  font-size: 16px;
  font-weight: 600;
  color: #24006a;
  margin-bottom: 6px;
}
.question_container {
  position: absolute;
  width: 261px;
  margin-top: 43px;
  margin-bottom: 20px;
}
#mission_counter {
  color: #24006a;
  margin-bottom: 0;
  font-size: 12px;
}
.question_title {
  color: #24006a;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.question_description {
  font-size: 12px;
}
.question_answer_container {
  margin-top: 24px;
}
.question_answer_container .btn-group {
  display: flex;
  flex-direction: column;
}
.question_answer_container .btn-group button {
  height: 50px;
  margin-right: 0;
  margin-bottom: 10px;
  border-radius: 6px !important;
  box-shadow: none !important;
  background: white;
  padding: 0 15px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1f0065;
  font-size: 14px;
  text-transform: capitalize;
}
.question_answer_container .btn {
  transition: .4s;
}
.question_answer_container .btn.active {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03) !important;
  border: solid 1px #00d7e5 !important;
  font-weight: bold;
  background:white !important;
  color: #24006a;
}
.question_answer_container .btn.active .fa {
  line-height: 17px;
  background: #00d7e5;
  padding: 7px 10px;
  border-radius: 100%;
  color: white;
  font-size: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.question_answer_container .form-group {
  margin-bottom: 0;
}
.btn_nav.btn_nav_next {
  flex-direction: row;
  width: 100%;
  display: flex;
  height: 41px;
  justify-content: center;
  background: #00d7e5;
  border-radius: 8px;
  border: 0;
  color: white;
}
.btn_nav.btn_nav_prev {
  background: white;
  border-radius: 8px;
  border: 0;
  width: 40px;
  margin-right: 5px;
}
.question_navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.question_answer_container input[type="text"] {
  border-radius: 6px !important;
  box-shadow: 0 10px 20px 0 rgba(19, 0, 62, 0.1) !important;
  background-color: #ffffff;
}
.question_answer_container select {
  border-radius: 6px !important;
  box-shadow: 0 10px 20px 0 rgba(19, 0, 62, 0.1) !important;
  background-color: #ffffff;
}
.question_answer_container input[type="range"] {
  box-shadow: none !important;
  background: transparent;
}

.question_answer_container input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
.question_answer_container input[type="range"]:focus {
  outline: none;
}
.question_answer_container input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #ffc200;
  border-radius: 2px;
}
.question_answer_container input[type="range"]::-webkit-slider-thumb {
  border: 4px solid #1f0065;
  height: 23px;
  width: 23px;
  border-radius: 100%;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -9px;
}
.question_answer_container
  input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ffc200;
}
.question_answer_container .range-inp-value {
  width: 80px;
  margin: 0 auto;
  text-align: center;
  padding: 0 !important;
  color: black;
  height: 50px !important;
  font-size: 17px;
}
