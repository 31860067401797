@import "../../../theme/colors.scss";

.navbar {
  padding: 12px 10px !important;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: 270px;
  max-width: 18%;
  background: #fff;
  transition: all 0.3s ease-in-out;
  transform-origin: bottom left;
  position: relative;

  box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  background: map-get($theme-colors, "primary");

  ul ul a {
    background: map-get($theme-colors, "primary");

    font-size: 14px !important;
    padding: 0px 0px 0px 53px !important;
    height: 50px !important;
  }

  &.active {
    // margin-left: -200px;
    margin-left: -215px;
    //transform: rotateY(100deg);

    // .sidebar-header {
    //   height: 0px;
    //   width: 0px;
    // }

    ul li a {
      width: 100%;
      padding: 0;
      justify-content: flex-end;

      transition: 400ms;

      img,
      i {
        width: 25px;
      }

      &:hover,
      &.active {
        transform: translateX(15px);
      }

      span > span {
        display: none;
      }
    }
    ul li ul li a {
      img,
      i {
        margin-right: 8px !important;
      }
    }
  }

  .sidebar-header {
    padding: 20px;
    position: relative;
    color: white;
    background: map-get($theme-colors, "primary");
    overflow: hidden;
    transition: 400ms;

    a:hover {
      animation-iteration-count: infinite;
    }
  }
}

.sidebar-header:hover {
  animation-iteration-count: infinite;
}
.sidebar-header::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background-image: url(/images/bg-1.jpg);
  background-size: cover;
  filter: brightness(0.6);
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 23px 17px 23px 53px;
  font-size: 1.3em;
  display: block;
  color: white;
  text-align: left;
  width: 90%;
  height: 74px;
  position: relative;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  margin: 5px 0;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: left;
    transition: 0ms;
    transform: scaleX(0);
    opacity: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    background: linear-gradient(
      to left,
      map-get($theme-colors, "primary") 0%,
      transparent 50%
    );
  }
}

#sidebar ul li a:hover,
#sidebar ul li a.active {
  color: white;
  animation-name: pulse;

  &:before {
    transform: scaleX(1);
    opacity: 1;
    background: map-get($theme-colors, "secondary");
    transition: 200ms;
  }
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
}

#sidebarCollapse {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 43px;
  left: 30px;
  background: map-get($theme-colors, "secondary");
  border-radius: 100%;
  width: 50px;
  height: 50px;
  color: white;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  transform-origin: center;
}

#sidebarCollapse.active {
  left: 30px;
  top: 43px;
  transform: rotateZ(180deg);
}

.top-header {
  padding: 40px 0px;
  margin: 0 auto;

  .top-dropdown {
    transition: 500ms;
    width: 80px;
    max-width: 300px;
    z-index: 9;

    .top-dropdown-menu {
      height: 0;
      overflow: hidden;
      position: absolute;
      background: white;
      top: 67px;
      width: 100%;
      z-index: 99;
      padding: 0;
      opacity: 0;
      transition: 500ms;

      .dropdown-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 62px;
        margin: 0;
        padding: 0 10px;
        font-size: 18px;
        font-weight: 800;
        color: #4e4e4e;
      }
    }
    .avatar-dropdown {
      border-radius: 0 !important;
      outline: none;
      font-size: 18px;
      font-weight: 800;
      color: #4e4e4e;
      text-transform: capitalize;
      background: transparent;
      span {
        width: 0;
        overflow: hidden;
        transition: 500ms;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &.show {
      width: 50%;
      .top-dropdown-menu {
        height: auto;
        opacity: 1;
        padding: 30px 20px;
      }
      .avatar-dropdown {
        border-bottom: 1px solid map-get($theme-colors, "light") !important;
        span {
          width: 200px;
          padding-left: 20px;
        }
      }
    }
  }
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  #sidebarCollapse {
    top: 28px !important;
    left: -25px;
    position: absolute;
  }
  .top-header {
    padding: 20px 30px;
  }
  .top-header .top-dropdown.show {
    width: 100%;
  }
}
