.custom-table {
  overflow: hidden;
  box-shadow: none !important;

  th {
    font-size: 18px;
    color: #b7b7b7;
    padding: 20px 0;
    border-bottom: 0;
  }

  td {
    font-size: 15px;
    font-weight: 800;
  }
}
